<template>
  <div id="page-dashboard">
    <div class="container">
      <DashboardList />
    </div>
  </div>
</template>

<script>
import DashboardList from '@/components/dashboard/DashboardList.vue';

export default {
  name: 'Dashboard',
  components: {
    DashboardList,
  },
};
</script>

<style scoped>

</style>
