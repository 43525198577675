export default function (value, maxLength = 20) {
  if (!value) return '';
  const valueString = value.toString();
  let abbr = '';
  if (valueString.length > maxLength) {
    const abbrEl = document.createElement('abbr');
    abbrEl.setAttribute('title', valueString);
    abbrEl.innerText = `${valueString.substring(0, maxLength)} ...`;
    abbr = abbrEl.outerHTML;
  } else {
    abbr = value;
  }
  return abbr;
}
