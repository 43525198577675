import ValidationHelper from '../helpers/ValidationHelper';
import CalcHelper from '../helpers/CalcHelper';

export default {
  twisting(state) {
    return CalcHelper.getTwisting(
      state.fixedBearingTwistingA,
      state.fixedBearingTwistingB,
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingTwistingWithSurcharge,
      state.fixedBearingTypeContactSurface,
    );
  },
  twistingTotal(state, getters) {
    return CalcHelper.getTwistingTotal(
      getters.twisting.totalA,
      getters.twisting.totalB,
    );
  },
  rotationDistribution(state) {
    return CalcHelper.getRotationDistribution(
      state.fixedBearingTwistingA,
      state.fixedBearingTwistingB,
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingTwistingWithSurcharge,
      state.fixedBearingTypeContactSurface,
    );
  },
  compressiveStress(state, getters) {
    return CalcHelper.getCompressiveStress(
      state.fixedBearingPressureForce,
      getters.areaNetto,
    );
  },
  areaBrutto(state) {
    return CalcHelper.getAreaBrutto(
      state.fixedBearingWidth,
      state.fixedBearingLength,
    );
  },
  areaNetto(state) {
    return CalcHelper.getAreaNetto(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingHolesCount,
      state.fixedBearingHolesDiameter,
    );
  },
  areaHoles(state) {
    return CalcHelper.getAreaHoles(
      state.fixedBearingHolesCount,
      state.fixedBearingHolesDiameter,
    );
  },
  formFactor(state) {
    return CalcHelper.getFormFactor(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingHolesCount,
      state.fixedBearingHolesDiameter,
      state.fixedBearingThickness,
    );
  },
  angleOfRotation(state, getters) {
    return CalcHelper.getAngleOfRotation(getters.twistingTotal);
  },
  permissibleHorizontalDisplacement(state) {
    return CalcHelper.getPermissibleHorizontalDisplacement(state.fixedBearingThickness);
  },
  typ100PermissibleCompressiveStress(state) {
    return Math.min(CalcHelper.getPermissibleCompressiveStress(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      state.fixedBearingHolesCount,
      state.fixedBearingHolesDiameter,
      state.fixedBearingTwistingA,
      state.fixedBearingTwistingB,
      'typ100',
      state.fixedBearingTwistingWithSurcharge,
      state.fixedBearingTypeContactSurface,
    ), 14);
  },
  typ100PermissiblePressureForce(state, getters) {
    return CalcHelper.getPermissiblePressureForce(
      getters.typ100PermissibleCompressiveStress,
      state.fixedBearingWidth,
      state.fixedBearingLength,
    );
  },
  typ100PermissibleTwisting(state) {
    return CalcHelper.getPermissibleTwisting(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      'typ100',
    );
  },
  typ100PermissibleAlphaResulting(state, getters) {
    return CalcHelper.getAlphaResulting(
      getters.typ100PermissibleTwisting.alphaA,
      getters.typ100PermissibleTwisting.alphaB,
      'typ100',
    );
  },
  typ150PermissibleCompressiveStress(state) {
    return Math.min(CalcHelper.getPermissibleCompressiveStress(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      state.fixedBearingHolesCount,
      state.fixedBearingHolesDiameter,
      state.fixedBearingTwistingA,
      state.fixedBearingTwistingB,
      'typ150',
      state.fixedBearingTwistingWithSurcharge,
      state.fixedBearingTypeContactSurface,
    ), 21);
  },
  typ150PermissiblePressureForce(state, getters) {
    return CalcHelper.getPermissiblePressureForce(
      getters.typ150PermissibleCompressiveStress,
      state.fixedBearingWidth,
      state.fixedBearingLength,
    );
  },
  typ150PermissibleTwisting(state) {
    return CalcHelper.getPermissibleTwisting(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      'typ150',
    );
  },
  typ150PermissibleAlphaResulting(state, getters) {
    return CalcHelper.getAlphaResulting(
      getters.typ150PermissibleTwisting.alphaA,
      getters.typ150PermissibleTwisting.alphaB,
      'typ150',
    );
  },
  typ200PermissibleCompressiveStress(state) {
    return Math.min(CalcHelper.getPermissibleCompressiveStress(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      state.fixedBearingHolesCount,
      state.fixedBearingHolesDiameter,
      state.fixedBearingTwistingA,
      state.fixedBearingTwistingB,
      'typ200',
      state.fixedBearingTwistingWithSurcharge,
      state.fixedBearingTypeContactSurface,
    ), 28);
  },
  typ200PermissiblePressureForce(state, getters) {
    return CalcHelper.getPermissiblePressureForce(
      getters.typ200PermissibleCompressiveStress,
      state.fixedBearingWidth,
      state.fixedBearingLength,
    );
  },
  typ200PermissibleTwisting(state) {
    return CalcHelper.getPermissibleTwisting(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      'typ200',
    );
  },
  typ200PermissibleAlphaResulting(state, getters) {
    return CalcHelper.getAlphaResulting(
      getters.typ200PermissibleTwisting.alphaA,
      getters.typ200PermissibleTwisting.alphaB,
      'typ200',
    );
  },
  errorDimensions(state) {
    const error = ValidationHelper.checkDimensions(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
    );
    return error;
  },
  errorDimensionsTyp100(state, getters) {
    const errorMinDimensions = ValidationHelper.checkMinDimensions(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      'typ100',
    );
    const error = {
      status: getters.errorDimensions.status || errorMinDimensions.status,
    };
    return error;
  },
  errorAlphaATyp100(state, getters) {
    const error = ValidationHelper.checkTwisting(
      getters.twisting.totalA,
      getters.typ100PermissibleTwisting.alphaA,
    );
    return error;
  },
  errorAlphaBTyp100(state, getters) {
    const error = ValidationHelper.checkTwisting(
      getters.twisting.totalB,
      getters.typ100PermissibleTwisting.alphaB,
    );
    return error;
  },
  errorAlphaResultingTyp100(state, getters) {
    const error = ValidationHelper.checkAlphaResulting(
      getters.twistingTotal,
      getters.typ100PermissibleAlphaResulting,
    );
    return error;
  },
  errorLoadCapacityTyp100(state, getters) {
    const error = ValidationHelper.checkLoadCapacity(
      getters.typ100PermissibleCompressiveStress,
      getters.compressiveStress,
    );
    return error;
  },
  typ100IsPossibile(state, getters) {
    let isPossibile = true;
    if (
      getters.errorDimensions.status
      || getters.errorDimensionsTyp100.status
      || getters.errorAlphaATyp100.status
      || getters.errorAlphaBTyp100.status
      || getters.errorAlphaResultingTyp100.status
      || getters.errorLoadCapacityTyp100.status
    ) {
      isPossibile = false;
    }
    return isPossibile;
  },
  errorDimensionsTyp150(state, getters) {
    const errorMinDimensions = ValidationHelper.checkMinDimensions(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      'typ150',
    );
    const error = {
      status: getters.errorDimensions.status || errorMinDimensions.status,
    };
    return error;
  },
  errorAlphaATyp150(state, getters) {
    const error = ValidationHelper.checkTwisting(
      getters.twisting.totalA,
      getters.typ150PermissibleTwisting.alphaA,
    );
    return error;
  },
  errorAlphaBTyp150(state, getters) {
    const error = ValidationHelper.checkTwisting(
      getters.twisting.totalB,
      getters.typ150PermissibleTwisting.alphaB,
    );
    return error;
  },
  errorAlphaResultingTyp150(state, getters) {
    const error = ValidationHelper.checkAlphaResulting(
      getters.twistingTotal,
      getters.typ150PermissibleAlphaResulting,
    );
    return error;
  },
  errorLoadCapacityTyp150(state, getters) {
    const error = ValidationHelper.checkLoadCapacity(
      getters.typ150PermissibleCompressiveStress,
      getters.compressiveStress,
    );
    return error;
  },
  typ150IsPossibile(state, getters) {
    let isPossibile = true;
    if (
      getters.errorDimensions.status
      || getters.errorDimensionsTyp150.status
      || getters.errorAlphaATyp150.status
      || getters.errorAlphaBTyp150.status
      || getters.errorAlphaResultingTyp150.status
      || getters.errorLoadCapacityTyp150.status
    ) {
      isPossibile = false;
    }
    return isPossibile;
  },
  errorDimensionsTyp200(state, getters) {
    const errorMinDimensions = ValidationHelper.checkMinDimensions(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      'typ200',
    );
    const error = {
      status: getters.errorDimensions.status || errorMinDimensions.status,
    };
    return error;
  },
  errorAlphaATyp200(state, getters) {
    const error = ValidationHelper.checkTwisting(
      getters.twisting.totalA,
      getters.typ200PermissibleTwisting.alphaA,
    );
    return error;
  },
  errorAlphaBTyp200(state, getters) {
    const error = ValidationHelper.checkTwisting(
      getters.twisting.totalB,
      getters.typ200PermissibleTwisting.alphaB,
    );
    return error;
  },
  errorAlphaResultingTyp200(state, getters) {
    const error = ValidationHelper.checkAlphaResulting(
      getters.twistingTotal,
      getters.typ200PermissibleAlphaResulting,
    );
    return error;
  },
  errorLoadCapacityTyp200(state, getters) {
    const error = ValidationHelper.checkLoadCapacity(
      getters.typ200PermissibleCompressiveStress,
      getters.compressiveStress,
    );
    return error;
  },
  typ200IsPossibile(state, getters) {
    let isPossibile = true;
    if (
      getters.errorDimensions.status
      || getters.errorDimensionsTyp200.status
      || getters.errorAlphaATyp200.status
      || getters.errorAlphaBTyp200.status
      || getters.errorAlphaResultingTyp200.status
      || getters.errorLoadCapacityTyp200.status
    ) {
      isPossibile = false;
    }
    return isPossibile;
  },
  warningStatusAreaHoles(state, getters) {
    return ValidationHelper.checkAreaHoles(
      getters.areaHoles,
      getters.areaBrutto,
    );
  },
  warningStatusMaxDimensions(state) {
    return ValidationHelper.checkMaxDimensionsWarnings(
      state.fixedBearingWidth,
      state.fixedBearingThickness,
    );
  },
  warningMessages(state, getters) {
    let errorMsg = [];
    const errorHoles = getters.warningStatusAreaHoles;
    if (errorHoles.status) {
      errorMsg = [...errorMsg, ...errorHoles.errorCodes];
    }
    const errorMaxDimensions = getters.warningStatusMaxDimensions;
    if (errorMaxDimensions.status) {
      errorMsg = [...errorMsg, ...errorMaxDimensions.errorCodes];
    }
    if (!state.fixedBearingTwistingWithSurcharge) {
      errorMsg.push('WITHOUT_SURCHARGE');
    }
    return errorMsg;
  },

  // ### Pyramidenlager
  pyramideSideC(state) {
    return CalcHelper.getPyramideMainStressedSide(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingPyramideAxisOfRotation,
    );
  },
  pyramidePermissiblePressureForce(state, getters) {
    return CalcHelper.getPermissiblePressureForce(
      getters.pyramidePermissibleCompressiveStress,
      state.fixedBearingWidth,
      state.fixedBearingLength,
    );
  },
  pyramidePermissibleCompressiveStress(state) {
    return CalcHelper.getPyramidePermissibleCompressiveStress(
      state.fixedBearingWidth,
      state.fixedBearingLength,
    );
  },
  pyramidePermissibleTwisting(state, getters) {
    return CalcHelper.getPyramidePermissibleTwisting(
      getters.pyramideSideC,
      state.fixedBearingPyramideStorageClass,
      getters.pyramidePermissibleCompressiveStress,
    );
  },
  pyramidePressureForce(state) {
    return CalcHelper.getPyramidePressureForce(
      state.fixedBearingPyramideConstantLoads,
      state.fixedBearingPyramideMaxVariableLoads,
    );
  },
  pyramideTwistingTotal(state, getters) {
    return CalcHelper.getPyramideTwistingTotal(
      state.fixedBearingPyramideStorageClass,
      state.fixedBearingPyramideTwisting,
      getters.pyramideSideC,
    );
  },
  pyramideTwistingImperfections(state, getters) {
    return getters.pyramideTwistingTotal - state.fixedBearingPyramideTwisting;
  },
  pyramideCompressiveStressMin(state, getters) {
    return CalcHelper.getPyramideCompressiveStressMin(
      getters.areaNetto,
      getters.areaBrutto,
      getters.areaHoles,
      state.fixedBearingPyramideConstantLoads,
      state.fixedBearingPyramideMinVariableLoads,
      state.fixedBearingPyramideMaxVariableLoads,
    );
  },
  pyramideCompressiveStressMax(state, getters) {
    return CalcHelper.getPyramideCompressiveStressMax(
      getters.areaNetto,
      getters.areaBrutto,
      getters.areaHoles,
      state.fixedBearingPyramideConstantLoads,
      state.fixedBearingPyramideMinVariableLoads,
      state.fixedBearingPyramideMaxVariableLoads,
    );
  },
  pyramideAngleOfRotation(state) {
    return CalcHelper.getPyramideAngleOfRotation(state.fixedBearingPyramideTwisting);
  },
  pyramideAngleOfRotationTotal(state, getters) {
    return CalcHelper.getPyramideAngleOfRotation(getters.pyramideTwistingTotal);
  },
  errorDimensionsPyramide(state, getters) {
    const errorMinDimensions = ValidationHelper.checkMinDimensions(
      state.fixedBearingWidth,
      state.fixedBearingLength,
      state.fixedBearingThickness,
      'pyramide',
    );
    const errorMaxDimensions = ValidationHelper.checkMaxDimensions(
      state.fixedBearingWidth,
      'pyramide',
    );
    const errorThickness = ValidationHelper.checkThickness(
      state.fixedBearingThickness,
      'pyramide',
    );
    return {
      status: getters.errorDimensions.status
      || errorMinDimensions.status
      || errorMaxDimensions.status
      || errorThickness.status,
    };
  },
  errorTwistingPyramide(state, getters) {
    const error = ValidationHelper.checkTwisting(
      getters.pyramideTwistingTotal,
      getters.pyramidePermissibleTwisting,
    );
    return error;
  },
  errorLoadCapacityPyramide(state, getters) {
    const error = ValidationHelper.checkLoadCapacity(
      getters.pyramidePermissibleCompressiveStress,
      getters.pyramideCompressiveStressMax,
    );
    return error;
  },
  pyramideIsPossibile(state, getters) {
    let isPossibile = true;
    if (
      getters.errorDimensions.status
      || getters.errorDimensionsPyramide.status
      || getters.errorTwistingPyramide.status
      || getters.errorLoadCapacityPyramide.status
    ) {
      isPossibile = false;
    }
    return isPossibile;
  },
};
