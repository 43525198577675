import OutputHelper from '@/helpers/OutputHelper';

export default {
  fixedBearingWidth(state, value) {
    state.fixedBearingWidth = OutputHelper.convertToInteger(value);
  },
  fixedBearingLength(state, value) {
    state.fixedBearingLength = OutputHelper.convertToInteger(value);
  },
  fixedBearingThickness(state, value) {
    state.fixedBearingThickness = OutputHelper.convertToInteger(value);
  },
  fixedBearingPressureForce(state, value) {
    state.fixedBearingPressureForce = OutputHelper.convertToNumber(value);
  },
  fixedBearingHolesDiameter(state, value) {
    state.fixedBearingHolesDiameter = OutputHelper.convertToInteger(value);
  },
  fixedBearingHolesCount(state, value) {
    state.fixedBearingHolesCount = OutputHelper.convertToInteger(value);
  },
  fixedBearingTwistingA(state, value) {
    state.fixedBearingTwistingA = OutputHelper.convertToNumber(value);
  },
  fixedBearingTwistingB(state, value) {
    state.fixedBearingTwistingB = OutputHelper.convertToNumber(value);
  },
  fixedBearingTwistingWithSurcharge(state, value) {
    state.fixedBearingTwistingWithSurcharge = OutputHelper.convertToBoolean(value);
  },
  fixedBearingTypeContactSurface(state, value) {
    state.fixedBearingTypeContactSurface = value;
  },
  fixedBearingHolesPosition(state, value) {
    state.fixedBearingHolesPosition = value;
  },
  fixedBearingX1(state, value) {
    state.fixedBearingX1 = OutputHelper.convertToNumber(value);
  },
  fixedBearingY1(state, value) {
    state.fixedBearingY1 = OutputHelper.convertToNumber(value);
  },
  fixedBearingX2(state, value) {
    state.fixedBearingX2 = OutputHelper.convertToNumber(value);
  },
  fixedBearingY2(state, value) {
    state.fixedBearingY2 = OutputHelper.convertToNumber(value);
  },
  fixedBearingX3(state, value) {
    state.fixedBearingX3 = OutputHelper.convertToNumber(value);
  },
  fixedBearingY3(state, value) {
    state.fixedBearingY3 = OutputHelper.convertToNumber(value);
  },
  fixedBearingX4(state, value) {
    state.fixedBearingX4 = OutputHelper.convertToNumber(value);
  },
  fixedBearingY4(state, value) {
    state.fixedBearingY4 = OutputHelper.convertToNumber(value);
  },
  fixedBearingPyramideConstantLoads(state, value) {
    state.fixedBearingPyramideConstantLoads = value;
  },
  fixedBearingPyramideMinVariableLoads(state, value) {
    state.fixedBearingPyramideMinVariableLoads = value;
  },
  fixedBearingPyramideMaxVariableLoads(state, value) {
    state.fixedBearingPyramideMaxVariableLoads = value;
  },
  fixedBearingPyramideTwisting(state, value) {
    state.fixedBearingPyramideTwisting = value;
  },
  fixedBearingPyramideAxisOfRotation(state, value) {
    state.fixedBearingPyramideAxisOfRotation = value;
  },
  fixedBearingPyramideStorageClass(state, value) {
    state.fixedBearingPyramideStorageClass = value;
  },
  fixedBearingSchemeType(state, value) {
    state.fixedBearingSchemeType = value;
  },
  fixedBearingUpdateVisual(state, value) {
    state.fixedBearingUpdateVisual = value;
  },
  fixedBearingSelectedBearingType(state, value) {
    state.fixedBearingSelectedBearingType = value;
  },
};
