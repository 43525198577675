import OutputHelper from './OutputHelper';

export default {

  // Einwirkende Spannung σz.Ed
  getCompressiveStress(f = 0, areaNetto = 0) {
    const fCasted = OutputHelper.convertToNumber(f);
    const areaNettoCasted = OutputHelper.convertToInteger(areaNetto);
    return fCasted / (areaNettoCasted / 1000);
  },

  // Zulässige Krafteinwirkung
  getPermissiblePressureForce(compressiveStress = 0, a = 0, b = 0) {
    const compressiveStressCasted = OutputHelper.convertToNumber(compressiveStress);
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    return compressiveStressCasted * aCasted * bCasted / 1000;
  },

  // Lagerfläche Brutto
  getAreaBrutto(a = 0, b = 0) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    return aCasted * bCasted;
  },

  // Lagerfläche Netto
  getAreaNetto(a = 0, b = 0, countHoles = 0, d = 0) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const countHolesCasted = OutputHelper.convertToInteger(countHoles);
    const dCasted = OutputHelper.convertToInteger(d);

    let areaNetto = 0;
    if (countHolesCasted === 0) {
      // Keine Bohrung vorhanden, dann netto = Brutto
      areaNetto = this.getAreaBrutto(aCasted, bCasted);
    } else if (countHolesCasted >= 1) {
      // Sonst Fläche der Bohrung von Gesamtfläche abziehen
      areaNetto = (
        this.getAreaBrutto(aCasted, bCasted)
        - this.getAreaHoles(countHolesCasted, dCasted)
      );
    }

    return areaNetto;
  },

  // Fläche Bohrungen
  getAreaHoles(countHoles = 0, d = 0) {
    const countHolesCasted = OutputHelper.convertToInteger(countHoles);
    const dCasted = OutputHelper.convertToInteger(d);
    return OutputHelper.convertToInteger(countHolesCasted * Math.PI * ((dCasted ** 2) / 4));
  },

  // Berechne Abstände Bohrungen
  getHolesPosition(a = 0, b = 0, countHoles = 0, d = 0) {
    const positions = {};
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const dCasted = OutputHelper.convertToInteger(d);
    const countHolesCasted = OutputHelper.convertToInteger(countHoles);
    if (countHolesCasted > 0 && countHolesCasted <= 3) {
      const xAxisParts = OutputHelper.convertToInteger(bCasted / countHolesCasted);
      const yAxisParts = OutputHelper.convertToInteger(aCasted / 2);
      for (let i = 1; i <= countHolesCasted; i += 1) {
        positions[`hole${i}`] = {
          x: (xAxisParts * (i - 1)) + (xAxisParts / 2),
          y: yAxisParts,
        };
      }
    } else if (countHolesCasted === 4) {
      const paddingEdge = 15;
      const halfDiameter = (dCasted / 2);

      positions.hole1 = {
        x: (halfDiameter + paddingEdge) >= 0 ? halfDiameter + paddingEdge : 0,
        y: (aCasted - halfDiameter - paddingEdge) >= 0 ? aCasted - halfDiameter - paddingEdge : 0,
      };

      positions.hole2 = {
        x: (halfDiameter + paddingEdge) >= 0 ? halfDiameter + paddingEdge : 0,
        y: (halfDiameter + paddingEdge) >= 0 ? halfDiameter + paddingEdge : 0,
      };

      positions.hole3 = {
        x: (bCasted - halfDiameter - paddingEdge) >= 0 ? bCasted - halfDiameter - paddingEdge : 0,
        y: (aCasted - halfDiameter - paddingEdge) >= 0 ? aCasted - halfDiameter - paddingEdge : 0,
      };

      positions.hole4 = {
        x: (bCasted - halfDiameter - paddingEdge) >= 0 ? bCasted - halfDiameter - paddingEdge : 0,
        y: (halfDiameter + paddingEdge) >= 0 ? halfDiameter + paddingEdge : 0,
      };
    }

    return positions;
  },

  // ### ALTE BERECHNUNG
  // Berechne L1 und L2 neu, entweder werden die Werte durch Eingabe eines
  // einzelnen Wertes neu berechnet oder durch das Ändern der Breite a
  getHolesPositionL1L2(editedValueType, l1, l2, a) {
    const l1Casted = OutputHelper.convertToInteger(l1);
    const l2Casted = OutputHelper.convertToInteger(l2);
    const aCasted = OutputHelper.convertToInteger(a);
    const pos = {
      l1: l1Casted,
      l2: l2Casted,
    };
    if (editedValueType === 'l1') {
      if (l1Casted < aCasted) {
        pos.l2 = aCasted - l1Casted;
      } else {
        pos.l1 = aCasted;
        pos.l2 = 0;
      }
    } else if (editedValueType === 'l2') {
      if (l2Casted < aCasted) {
        pos.l1 = aCasted - l2Casted;
      } else {
        pos.l2 = aCasted;
        pos.l1 = 0;
      }
      pos.l1 = aCasted - l2Casted;
    } else if (editedValueType === 'newCalc') {
      // neu berechnen wenn Länge, Breite oder Anzahl der Löcher geändert wurde
      pos.l1 = OutputHelper.convertToInteger(aCasted / 2);
      pos.l2 = OutputHelper.convertToInteger(aCasted / 2);
    }

    pos.l1 = (pos.l1 < 0 ? 0 : pos.l1);
    pos.l2 = (pos.l2 < 0 ? 0 : pos.l2);

    return pos;
  },

  // ### ALTE BERECHNUNG
  // Berechne m1,m2 und m0 neu, entweder werden die Werte durch Eingabe eines
  // einzelnen Wertes neu berechnet oder durch das Ändern der Länge b
  // oder durch das Ändern der Anzahl der Bohrungen
  getHolesPositionM1M2M0(editedValueType, m1, m2, m0, b, countHoles) {
    const m1Casted = OutputHelper.convertToInteger(m1);
    const m2Casted = OutputHelper.convertToInteger(m2);
    const m0Casted = OutputHelper.convertToInteger(m0);
    const bCasted = OutputHelper.convertToInteger(b);
    const countHolesCasted = OutputHelper.convertToInteger(countHoles);
    const pos = {
      m1: m1Casted,
      m2: m2Casted,
      m0: m0Casted,
    };
    if (editedValueType === 'm1') {
      if (countHolesCasted === 1) {
        if (m1Casted < bCasted) {
          pos.m2 = bCasted - m1Casted;
        } else {
          pos.m1 = bCasted;
          pos.m2 = 0;
        }
      } else if (countHolesCasted === 2) {
        if (m1Casted < bCasted) {
          // Berechne übrig bleibende Fläche für m2 und m0
          // Wenn größer als 0, dann bleibende Fläche m0 zuweisen.
          // Sonst m0 auf 0 setzen und den Rest von m2 abziehen
          const remainingArea = bCasted - (m1Casted + m2Casted);
          if (remainingArea >= 0) {
            pos.m0 = OutputHelper.convertToInteger(remainingArea);
          } else {
            pos.m2 = m2Casted + remainingArea;
            pos.m0 = 0;
          }
        } else {
          pos.m1 = bCasted;
          pos.m2 = 0;
          pos.m0 = 0;
        }
      }
    } else if (editedValueType === 'm2') {
      if (countHolesCasted === 1) {
        if (m2Casted < bCasted) {
          pos.m1 = bCasted - m2Casted;
        } else {
          pos.m1 = 0;
          pos.m2 = bCasted;
        }
      } else if (countHolesCasted === 2) {
        if (m2Casted < bCasted) {
          // Berechne übrig bleibende Fläche für m1 und m0
          // Wenn größer als 0, dann bleibende Fläche m0 zuweisen.
          // Sonst m0 auf 0 setzen und den Rest von m1 abziehen
          const remainingArea = bCasted - (m1Casted + m2Casted);
          if (remainingArea >= 0) {
            pos.m0 = OutputHelper.convertToInteger(remainingArea);
          } else {
            pos.m1 = m1Casted + remainingArea;
            pos.m0 = 0;
          }
        } else {
          pos.m1 = 0;
          pos.m2 = bCasted;
          pos.m0 = 0;
        }
      }
    } else if (editedValueType === 'm0') {
      if (m0Casted < bCasted) {
        const remainingArea = bCasted - m0;
        pos.m1 = OutputHelper.convertToInteger(remainingArea / 2);
        pos.m2 = OutputHelper.convertToInteger(remainingArea / 2);
      } else {
        pos.m1 = 0;
        pos.m2 = 0;
        pos.m0 = bCasted;
      }
    } else if (editedValueType === 'newCalc') {
      // neu berechnen wenn Länge, Breite oder Anzahl der Löcher geändert wurde
      if (countHolesCasted === 0) {
        pos.m1 = 0;
        pos.m2 = 0;
        pos.m0 = 0;
      } else if (countHolesCasted === 1) {
        pos.m1 = OutputHelper.convertToInteger(bCasted / 2);
        pos.m2 = OutputHelper.convertToInteger(bCasted / 2);
        pos.m0 = 0;
      } else if (countHolesCasted === 2) {
        pos.m1 = OutputHelper.convertToInteger(bCasted / 3);
        pos.m2 = OutputHelper.convertToInteger(bCasted / 3);
        pos.m0 = OutputHelper.convertToInteger(bCasted / 3);
      }
    }

    pos.m1 = (pos.m1 < 0 ? 0 : pos.m1);
    pos.m2 = (pos.m2 < 0 ? 0 : pos.m2);
    pos.m0 = (pos.m0 < 0 ? 0 : pos.m0);

    return pos;
  },

  // Formfaktor S
  getFormFactor(a, b, countHoles, d, t) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const countHolesCasted = OutputHelper.convertToInteger(countHoles);
    const dCasted = OutputHelper.convertToInteger(d);
    const tCasted = OutputHelper.convertToInteger(t);
    const formFactor = (aCasted * bCasted) / (2 * tCasted * (aCasted + bCasted));
    const formFactorDrilling = (
      (
        aCasted * bCasted
        - ((Math.PI / 4) * ((dCasted ** 2) * countHolesCasted))
      ) / (
        2 * tCasted * (aCasted + bCasted) + tCasted * Math.PI * (dCasted * countHolesCasted)
      )
    );
    return {
      formFactor,
      formFactorDrilling,
    };
  },

  // Eta berechnen / Seitenverhältnisbeiwert
  getEta(a, b) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const baRatio = bCasted / aCasted;
    let eta = 0;
    if (baRatio < 10) {
      eta = (
        0.000795 * (baRatio ** 5)
        - 0.062212 * (baRatio ** 4)
        + 1.367441 * (baRatio ** 3)
        - 13.448194 * (baRatio ** 2)
        + 67.954482 * baRatio + 153.35
      ) / 1004;
    } else {
      eta = 0.000222 * baRatio + 0.310778;
    }

    return eta;
  },

  // Ermittlung der Gesamtverdrehung
  getTwistingTotal(totalAlphaA, totalAlphaB) {
    return ((totalAlphaA ** 2) + (totalAlphaB ** 2)) ** 0.5;
  },

  // Ermittlung der Rotationsverteilung
  getRotationDistribution(alphaA, alphaB, a, b, withSurcharge, contactSurface) {
    const alphaACasted = OutputHelper.convertToNumber(alphaA);
    const alphaBCasted = OutputHelper.convertToNumber(alphaB);
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);

    // Rotation
    const rotationA = (alphaACasted > 0);
    const rotationB = (alphaBCasted > 0);

    // Auflagerverdrehung / Zweiaxial
    const biaxial = (rotationA && rotationB);

    // Auflagerverdrehung / Ohne
    const noneAxial = (alphaACasted === 0 && alphaBCasted === 0);

    // Unebenheit
    let surchargeRoughnessA = 625 / bCasted;
    if (contactSurface === 'steel') {
      surchargeRoughnessA = 625 / (2 * bCasted);
    }
    let surchargeRoughnessB = 625 / aCasted;
    if (contactSurface === 'steel') {
      surchargeRoughnessB = 625 / (2 * aCasted);
    }

    // Schiefwingkleit
    const obliqueAngleA = 10;
    const obliqueAngleB = 10;

    // Anteil
    const twistingQuotaA = bCasted / (bCasted + aCasted);
    const twistingQuotaB = 1 - twistingQuotaA;

    // Gesamtzuschlag
    let totalSurchargeA = 0.0;
    let totalSurchargeB = 0.0;
    if (withSurcharge) {
      totalSurchargeA = surchargeRoughnessA + twistingQuotaA * obliqueAngleA;
      totalSurchargeB = surchargeRoughnessB + twistingQuotaB * obliqueAngleB;
    }

    // Rotationsverteilung
    let rotationDistributionA = 0;
    let rotationDistributionB = 0;

    if (withSurcharge && !rotationA) {
      rotationDistributionA = surchargeRoughnessA + obliqueAngleA * twistingQuotaA;
    } else if (rotationA && withSurcharge && !biaxial) {
      rotationDistributionA = obliqueAngleA + surchargeRoughnessA;
    } else if (biaxial) {
      rotationDistributionA = totalSurchargeA;
    }
    if (withSurcharge && !rotationB) {
      rotationDistributionB = surchargeRoughnessB + obliqueAngleB * twistingQuotaB;
    } else if (rotationB && withSurcharge && !biaxial) {
      rotationDistributionB = obliqueAngleB + surchargeRoughnessB;
    } else if (biaxial) {
      rotationDistributionB = totalSurchargeB;
    }

    // Ohne Auflagerverdrehung Rotationsverteilung auf alpha b
    if (noneAxial && withSurcharge) {
      rotationDistributionA = 0;
      rotationDistributionB = obliqueAngleB + surchargeRoughnessB;
    }

    return {
      rotationDistributionA,
      rotationDistributionB,
    };
  },

  // Ermittlung der Verdrehung für "αa,d  gesamt" und "αb,d  gesamt"
  getTwisting(alphaA, alphaB, a, b, withSurcharge, contactSurface) {
    const alphaACasted = OutputHelper.convertToNumber(alphaA);
    const alphaBCasted = OutputHelper.convertToNumber(alphaB);
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);

    // Rotation
    const rotationA = (alphaACasted > 0);
    const rotationB = (alphaBCasted > 0);

    // Zweiaxial
    const biaxial = (rotationA && rotationB);

    // Auflagerverdrehung / Ohne
    const noneAxial = (alphaACasted === 0 && alphaBCasted === 0);

    // Unebenheit
    let surchargeRoughnessA = 625 / bCasted;
    if (contactSurface === 'steel') {
      surchargeRoughnessA = 625 / (2 * bCasted);
    }
    let surchargeRoughnessB = 625 / aCasted;
    if (contactSurface === 'steel') {
      surchargeRoughnessB = 625 / (2 * aCasted);
    }

    // Schiefwingkleit
    const obliqueAngleA = 10;
    const obliqueAngleB = 10;

    // Anteil
    let twistingQuotaA = 0;
    let twistingQuotaB = 1;
    if (!noneAxial) {
      twistingQuotaA = bCasted / (bCasted + aCasted);
      twistingQuotaB = 1 - twistingQuotaA;
    }

    // Gesamtzuschlag
    let totalSurchargeA = 0.0;
    let totalSurchargeB = 0.0;
    if (withSurcharge) {
      totalSurchargeA = surchargeRoughnessA + twistingQuotaA * obliqueAngleA;
      totalSurchargeB = surchargeRoughnessB + twistingQuotaB * obliqueAngleB;
    }

    // Rotationsverteilung
    let rotationDistributionA = 0;
    let rotationDistributionB = 0;

    if (withSurcharge && !rotationA) {
      rotationDistributionA = surchargeRoughnessA + obliqueAngleA * twistingQuotaA;
    } else if (rotationA && withSurcharge && !biaxial) {
      rotationDistributionA = obliqueAngleA + surchargeRoughnessA;
    } else if (biaxial) {
      rotationDistributionA = totalSurchargeA;
    }

    if (withSurcharge && !rotationB) {
      rotationDistributionB = surchargeRoughnessB + obliqueAngleB * twistingQuotaB;
    } else if (rotationB && withSurcharge && !biaxial) {
      rotationDistributionB = obliqueAngleB + surchargeRoughnessB;
    } else if (biaxial) {
      rotationDistributionB = totalSurchargeB;
    }

    // Ohne Auflagerverdrehung Rotationsverteilung auf alpha b
    if (noneAxial && withSurcharge) {
      rotationDistributionA = 0;
      rotationDistributionB = obliqueAngleB + surchargeRoughnessB;
    }

    // Gesamt
    let twistingTotalA = 0;
    if (alphaACasted === 0 && alphaBCasted === 0) {
      twistingTotalA = rotationDistributionA;
    } else if (alphaACasted !== 0) {
      twistingTotalA = rotationDistributionA + alphaACasted;
    }
    let twistingTotalB = 0;
    if (alphaACasted === 0 && alphaBCasted === 0) {
      twistingTotalB = rotationDistributionB;
    } else if (alphaBCasted !== 0) {
      twistingTotalB = rotationDistributionB + alphaBCasted;
    }
    /*
    console.log('Unebenheit');
    console.log(surchargeRoughnessA);
    console.log(surchargeRoughnessB);
    console.log('Anteil');
    console.log(twistingQuotaA);
    console.log(twistingQuotaB);
    console.log('Gesamtzuschlag');
    console.log(totalSurchargeA);
    console.log(totalSurchargeB);
    console.log('Rotation');
    console.log(rotationA);
    console.log(rotationB);
    console.log('Zweiaxial');
    console.log(biaxial);
    console.log('Rotationsverteilung');
    console.log(rotationDistributionA);
    console.log(rotationDistributionB);
    console.log('Totale Verdrehung');
    console.log(twistingTotalA);
    console.log(twistingTotalB);
    */
    return {
      totalA: twistingTotalA,
      totalB: twistingTotalB,
    };
  },

  // Berechne hauptbeanspruchte Seite c
  getMainStressedSide(alphaA, alphaB, a, b, t) {
    const alphaACasted = OutputHelper.convertToNumber(alphaA);
    const alphaBCasted = OutputHelper.convertToNumber(alphaB);
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const tCasted = OutputHelper.convertToInteger(t);

    const permissibleTwistingAlphaA = 0.45 * tCasted / bCasted;
    const permissibleTwistingAlphaB = 0.45 * tCasted / aCasted;
    const kAlphaA = alphaACasted / (permissibleTwistingAlphaA * 1000);
    const kAlphaB = alphaBCasted / (permissibleTwistingAlphaB * 1000);

    let mainStressedSide = bCasted;
    if (kAlphaB >= kAlphaA) {
      mainStressedSide = aCasted;
    }

    return mainStressedSide;
  },

  // Berechne Vedrehungszuschlag Unebenheit / αUnebenheit
  getSurchargeRoughness(mainStressedSide, contactSurface) {
    const mainStressedSideCasted = OutputHelper.convertToInteger(mainStressedSide);
    // Berechne Unebenheit
    let surchargeRoughness = 0;
    if (contactSurface === 'steel') {
      surchargeRoughness = 625 / (2 * mainStressedSideCasted);
    } else {
      surchargeRoughness = 625 / mainStressedSideCasted;
    }

    return surchargeRoughness;
  },

  // Ermittlung der Tragfähigkeit - zulässige Druckspannung / σzRd
  // eslint-disable-next-line max-len
  getPermissibleCompressiveStress(a, b, t, countHoles, d, alphaA, alphaB, bearingType, withSurcharge, contactSurface) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const tCasted = OutputHelper.convertToInteger(t);
    const countHolesCasted = OutputHelper.convertToInteger(countHoles);
    const mainStressedSide = this.getMainStressedSide(alphaA, alphaB, a, b, t);
    const formFactor = this.getFormFactor(a, b, countHoles, d, t);
    let formFactorValue = 0;
    if (countHolesCasted === 0) {
      formFactorValue = formFactor.formFactor;
    } else if (countHolesCasted >= 1) {
      formFactorValue = formFactor.formFactorDrilling;
    }
    const loadCapacityUpgright = this.getLoadCapacity(formFactorValue, bearingType);
    const eta = this.getEta(a, b);
    const innerResistance = this.getInnerResistance(loadCapacityUpgright, a, t, eta);
    const twistingTotal = this.getTwisting(
      alphaA, alphaB, a, b,
      withSurcharge, contactSurface,
    );
    const feedModule = this.getFeedModule(bearingType);
    let compressiveStress = 0;
    compressiveStress = (
      innerResistance
      - (twistingTotal.totalB / 1000)
      * (feedModule / 2)
      * ((aCasted / tCasted) ** 2)
      - (twistingTotal.totalA / 1000)
      * (feedModule / 2)
      * ((bCasted / tCasted) ** 2)
    )
    * mainStressedSide / tCasted
    * eta;

    /*
    console.log(`#### Typ: ${bearingType}`);
    console.log(`Seite a: ${aCasted}`);
    console.log(`Seite b: ${bCasted}`);
    console.log(`t: ${tCasted}`);
    console.log(`Anzahl Bohrungen: ${countHolesCasted}`);
    console.log(`Verdrehung Total A: ${twistingTotal.totalA}`);
    console.log(`Verdrehung Total B: ${twistingTotal.totalB}`);
    console.log(`Formfaktor: ${formFactor.formFactor}`);
    console.log(`Formfaktor Bohrung: ${formFactor.formFactorDrilling}`);
    console.log(`Tragfähigkeit: ${loadCapacityUpgright}`);
    console.log(`Innerer Widerstand ftrd: ${innerResistance}`);
    console.log(`Schubmodul: ${feedModule}`);
    console.log(`Hauptbeanspruchte Seite: ${mainStressedSide}`);
    console.log(`Eta: ${eta}`);
    console.log(`zulässige Druckspannung: ${compressiveStress}`);
    */
    return compressiveStress;
  },

  // Ermittlung Schubmodul G
  getFeedModule(bearingType) {
    let feedModule = 0;
    if (bearingType === 'typ150' || bearingType === 'typ100') {
      feedModule = 1.2;
    } else if (bearingType === 'typ200') {
      feedModule = 1.5;
    }
    return feedModule;
  },

  // Berechne Rꓕd = Bemessungswert der zugehörigen Tragfähigkeit des Lagers [ N/mm2]
  // senkrecht zur Lagerebene in Abhängigkeit vom Formfaktor S
  getLoadCapacity(formFactor, bearingType) {
    const formFactorCasted = OutputHelper.convertToNumber(formFactor);
    let loadCapacityUpright = 0;
    const bearingTypeValues = {
      typ100: [
        {
          min: 0.83,
          max: 2.33,
          value: 5.3805 * formFactorCasted - 0.6536,
        },
        {
          min: 2.34,
          max: 2.50,
          value: 10.635 * formFactorCasted - 12.89,
        },
        {
          min: 2.51,
          max: 5,
          value: 8.4004 * formFactorCasted - 7.3293,
        },
        {
          min: 5.01,
          value: 34.7,
        },
      ],
      typ150: [
        {
          min: 0.88,
          max: 5.00,
          value: 7.22 * formFactorCasted - 3.39,
        },
        {
          min: 5.01,
          max: 7.00,
          value: 8.95 * formFactorCasted - 12.02,
        },
        {
          min: 7.01,
          max: 10,
          value: 1.96 * formFactorCasted + 36.86,
        },
        {
          min: 10.01,
          value: 56.5,
        },
      ],
      typ200: [
        {
          min: 0.88,
          max: 3.75,
          value: 17.17 * formFactorCasted - 11.32,
        },
        {
          min: 3.76,
          max: 4.00,
          value: 35.94 * formFactorCasted - 81.67,
        },
        {
          min: 4.01,
          max: 10,
          value: 1.17 * formFactorCasted + 57.39,
        },
        {
          min: 10.01,
          value: 69.1,
        },
      ],
    };
    if (Array.isArray(bearingTypeValues[bearingType])) {
      bearingTypeValues[bearingType].forEach((item) => {
        if (formFactorCasted >= item.min) {
          loadCapacityUpright = item.value;
        }
      });
    }

    return loadCapacityUpright;
  },

  // Berechne ftrd = Rechenwert des inneren Widerstandes
  /*
  getInnerResistance(formFactor, loadCapacityUpright) {
    const formFactorCasted = OutputHelper.convertToNumber(formFactor);
    const loadCapacityUprightCasted = OutputHelper.convertToNumber(loadCapacityUpright);
    let innerResistance = 0;
    if (formFactor > 0 && loadCapacityUpright > 0) {
      innerResistance = 1.2 * (loadCapacityUprightCasted / formFactorCasted);
    }
    return innerResistance;
  },
  */

  getInnerResistance(loadCapacityUpright, a, t, eta) {
    const aCasted = OutputHelper.convertToNumber(a);
    const loadCapacityUprightCasted = OutputHelper.convertToNumber(loadCapacityUpright);
    const tCasted = OutputHelper.convertToNumber(t);
    const etaCasted = OutputHelper.convertToNumber(eta);
    let innerResistance = 0;
    if (loadCapacityUpright > 0) {
      innerResistance = (loadCapacityUprightCasted / etaCasted) * (tCasted / aCasted);
    }

    return innerResistance;
  },

  // Zulässige Verdrehung
  getPermissibleTwisting(a, b, t, bearingType) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const tCasted = OutputHelper.convertToInteger(t);

    const permissibleTwisting = {
      alphaA: 0,
      alphaB: 0,
    };
    if (bearingType === 'typ100') {
      permissibleTwisting.alphaA = 450 * tCasted / bCasted;
      if (permissibleTwisting.alphaA > 48) {
        permissibleTwisting.alphaA = 48;
      }
      permissibleTwisting.alphaB = 450 * tCasted / aCasted;
      if (permissibleTwisting.alphaB > 48) {
        permissibleTwisting.alphaB = 48;
      }
    } else if (bearingType === 'typ150') {
      permissibleTwisting.alphaA = 400 * tCasted / bCasted;
      if (permissibleTwisting.alphaA > 48) {
        permissibleTwisting.alphaA = 48;
      }
      permissibleTwisting.alphaB = 400 * tCasted / aCasted;
      if (permissibleTwisting.alphaB > 48) {
        permissibleTwisting.alphaB = 48;
      }
    } else if (bearingType === 'typ200') {
      permissibleTwisting.alphaA = 450 * tCasted / bCasted;
      if (permissibleTwisting.alphaA > 40) {
        permissibleTwisting.alphaA = 40;
      }
      permissibleTwisting.alphaB = 450 * tCasted / aCasted;
      if (permissibleTwisting.alphaB > 40) {
        permissibleTwisting.alphaB = 40;
      }
    }

    return permissibleTwisting;
  },

  // α Resultierende → zulässige Verdrehung
  getAlphaResulting(twistingTotalA, twistingTotalB, bearingType) {
    const twistingTotalACasted = OutputHelper.convertToNumber(twistingTotalA);
    const twistingTotalBCasted = OutputHelper.convertToNumber(twistingTotalB);
    let alphaResulting = 0;
    if (twistingTotalACasted > 0 || twistingTotalBCasted > 0) {
      alphaResulting = ((twistingTotalACasted ** 2) + (twistingTotalBCasted ** 2)) ** 0.5;
    }
    if ((bearingType === 'typ100' || bearingType === 'typ150') && alphaResulting > 48) {
      alphaResulting = 48;
    } else if (bearingType === 'typ200' && alphaResulting > 40) {
      alphaResulting = 40;
    }
    return alphaResulting;
  },

  // Drehwinkel
  getAngleOfRotation(alpha) {
    const alphaCasted = OutputHelper.convertToNumber(alpha);
    return (Math.atan(alphaCasted / 1000)) * 180 / Math.PI;
  },

  // Horizontale Verschiebung u rel
  getPermissibleHorizontalDisplacement(t) {
    const tCasted = OutputHelper.convertToInteger(t);
    return 0.6 * (tCasted - 2);
  },

  // Pyramidenlager = Ermittlung der Tragfähigkeit - zulässige Druckspannung / σzRd
  getPyramidePermissibleCompressiveStress(a, b) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const formFactor = (aCasted * bCasted)
      / (2 * 7 * (aCasted + bCasted));
    let loadCapacityUpright = 10;
    if (2 * formFactor <= 10) {
      loadCapacityUpright = 2 * formFactor;
    }
    return loadCapacityUpright;
  },

  // Pyramidenlager = Last F
  getPyramidePressureForce(steadyLoad, maxLoad) {
    const steadyLoadCasted = OutputHelper.convertToNumber(steadyLoad);
    const maxLoadCasted = OutputHelper.convertToNumber(maxLoad);
    return steadyLoadCasted + maxLoadCasted;
  },

  // Pyramidenlager = Lagerungsklasse
  getPyramideStorageClass(steadyLoad, variableMinLoad, variableMaxLoad) {
    const steadyLoadCasted = OutputHelper.convertToNumber(steadyLoad);
    const variableMinLoadCasted = OutputHelper.convertToNumber(variableMinLoad);
    const variableMaxLoadCasted = OutputHelper.convertToNumber(variableMaxLoad);
    const k = steadyLoadCasted
      / (steadyLoadCasted + Math.max(variableMinLoadCasted, variableMaxLoadCasted));
    let storageClass = [1, 2];
    if (k < 0.75) {
      storageClass = [1];
    }
    return storageClass;
  },

  // Pyramidenlager = Ermittlung der wirkenden Verdrehung
  getPyramideTwistingTotal(storageClass, existingAlpha, c) {
    const storageClassCasted = OutputHelper.convertToInteger(storageClass);
    const existingAlphaCasted = OutputHelper.convertToNumber(existingAlpha);
    const cCasted = OutputHelper.convertToInteger(c);
    let existingAlphaTotal;
    if (storageClassCasted === 1) {
      existingAlphaTotal = existingAlphaCasted + 10 + 625 / cCasted;
    } else {
      existingAlphaTotal = existingAlphaCasted;
    }
    return existingAlphaTotal;
  },

  // Pyramidenlager = Zulässige Verdrehung
  getPyramidePermissibleTwisting(c, storageClass, loadCapacityUpright) {
    const cCasted = OutputHelper.convertToInteger(c);
    const storageClassCasted = OutputHelper.convertToInteger(storageClass);
    const loadCapacityUprightCasted = OutputHelper.convertToNumber(loadCapacityUpright);
    let permissibleTwisting;
    if (storageClassCasted === 1) {
      permissibleTwisting = (
        (2.5 / cCasted)
        + (210 / (cCasted ** 2))
        - (1900 * loadCapacityUprightCasted / (cCasted ** 3))
      ) * 1000;
    } else if (storageClassCasted === 2) {
      permissibleTwisting = 1915 * (cCasted ** -0.87263641110653);
    }
    return permissibleTwisting;
  },

  // Pyramidenlager = Hauptbeanspruchte Seite
  getPyramideMainStressedSide(a, b, twistingToSide) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    let c;
    if (twistingToSide === 'a') {
      c = aCasted;
    } else if (twistingToSide === 'b') {
      c = bCasted;
    }
    return c;
  },

  // Pyramidenlager = Minimale Druckspannung
  getPyramideCompressiveStressMin(
    areaNetto,
    areaBrutto,
    areaDrilling,
    constantLoads,
    minVariableLoads,
    maxVariableLoads,
  ) {
    const areaNettoCasted = OutputHelper.convertToInteger(areaNetto);
    const areaBruttoCasted = OutputHelper.convertToInteger(areaBrutto);
    const areaDrillingCasted = OutputHelper.convertToInteger(areaDrilling);
    const constantLoadsCasted = OutputHelper.convertToFloat(constantLoads, 1);
    const minVariableLoadsCasted = OutputHelper.convertToFloat(minVariableLoads, 1);
    const maxVariableLoadsCasted = OutputHelper.convertToFloat(maxVariableLoads, 1);

    const percentAreaDrilling = areaDrillingCasted / areaBruttoCasted * 100;
    const minPower = Math.min(
      constantLoadsCasted + minVariableLoadsCasted,
      constantLoadsCasted + maxVariableLoadsCasted,
    );
    let compressiveStressMin;
    if (percentAreaDrilling > 10) {
      compressiveStressMin = minPower * 1000 / areaNettoCasted;
    } else {
      compressiveStressMin = minPower * 1000 / areaBruttoCasted;
    }
    return compressiveStressMin;
  },

  // Pyramidenlager = Maximale Druckspannung
  getPyramideCompressiveStressMax(
    areaNetto,
    areaBrutto,
    areaDrilling,
    constantLoads,
    minVariableLoads,
    maxVariableLoads,
  ) {
    const areaNettoCasted = OutputHelper.convertToInteger(areaNetto);
    const areaBruttoCasted = OutputHelper.convertToInteger(areaBrutto);
    const areaDrillingCasted = OutputHelper.convertToInteger(areaDrilling);
    const constantLoadsCasted = OutputHelper.convertToFloat(constantLoads, 1);
    const minVariableLoadsCasted = OutputHelper.convertToFloat(minVariableLoads, 1);
    const maxVariableLoadsCasted = OutputHelper.convertToFloat(maxVariableLoads, 1);

    const percentAreaDrilling = areaDrillingCasted / areaBruttoCasted * 100;
    const maxPower = Math.max(
      constantLoadsCasted + minVariableLoadsCasted,
      constantLoadsCasted + maxVariableLoadsCasted,
    );
    let compressiveStressMin;
    if (percentAreaDrilling > 10) {
      compressiveStressMin = maxPower * 1000 / areaNettoCasted;
    } else {
      compressiveStressMin = maxPower * 1000 / areaBruttoCasted;
    }
    return compressiveStressMin;
  },

  // Pyramidenlager = Drehwinkel
  getPyramideAngleOfRotation(alpha) {
    const alphaCasted = OutputHelper.convertToNumber(alpha);
    return Math.atan(alphaCasted / 1000) * 180 / Math.PI;
  },
};
