import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle, faChevronDown, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueNumerals from 'vue-numerals';

import router from './router';
import App from './App.vue';
import i18n from './i18n';
import store from './store/Store';
import OutputHelper from './helpers/OutputHelper';
import DataOptionsHelper from './helpers/DataOptionsHelper';
import ValidationHelper from './helpers/ValidationHelper';
import CalcHelper from './helpers/CalcHelper';
import AbbrFilter from './filters/abbr';

// Ausgaben anpassen
Vue.use(VueNumerals, {
  locale: 'de',
});

// Fontawesome
library.add(faQuestionCircle, faChevronDown, faExclamationTriangle);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Bootstrap
Vue.config.productionTip = false;
Vue.use(BootstrapVue);

// Filters
Vue.filter('abbr', AbbrFilter);

// Helper
Vue.prototype.$outputHelper = OutputHelper;
Vue.prototype.$dataOptionsHelper = DataOptionsHelper;
Vue.prototype.$calcHelper = CalcHelper;
Vue.prototype.$validationHelper = ValidationHelper;

// Error Handler
const errorHandler = (errMsg) => {
  fetch('/api/logs', {
    method: 'POST',
    body: JSON.stringify({
      msg: errMsg,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.querySelector('meta[name="csrf-token"]').getAttribute('content')}`,
    },
  });
};

Vue.config.errorHandler = (err) => {
  const modal = `<div class="modal fade show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Es ist ein Fehler aufgetreten</h5>
          </div>
          <div class="modal-body">
            Leider ist ein Fehler aufgetreten. Bitte klicken Sie auf "Neu laden", um den Rechner neu zu starten.
          </div>
          <div class="modal-footer">
            <a class="btn btn-error-reload btn-primary">Neu laden</a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop"></div>`;

  document.body.insertAdjacentHTML('beforeend', modal);
  document.body.classList.add('modal-open');
  document.querySelector('.btn-error-reload').addEventListener('click', () => {
    window.location.reload(true);
  });

  errorHandler(JSON.stringify(err));
};

window.addEventListener('unhandledrejection', (event) => {
  errorHandler(JSON.stringify(event));
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
