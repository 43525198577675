import OutputHelper from './OutputHelper';

export default {

  // Durchmesser Bohrungen
  checkDiameter(d = 0) {
    const dCasted = OutputHelper.convertToInteger(d);
    const error = {
      status: false,
      errorCodes: ['DIAMETER_TOO_BIG'],
    };
    if (dCasted > 50) {
      error.status = true;
    }

    return error;
  },

  // Ermittlung Tragfähigkeit
  checkLoadCapacity(permissibleCompressiveStress, existingCompressiveStress) {
    // eslint-disable-next-line max-len
    const permissibleCompressiveStressCasted = OutputHelper.convertToNumber(permissibleCompressiveStress);
    const existingCompressiveStressCasted = OutputHelper.convertToNumber(existingCompressiveStress);
    const error = {
      status: false,
    };
    if (existingCompressiveStressCasted > permissibleCompressiveStressCasted) {
      error.status = true;
    }

    return error;
  },

  // Überprüfe Abmaße
  checkDimensions(a, b) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const error = {
      status: false,
      errorCodes: [],
    };
    if (aCasted > bCasted) {
      error.status = true;
      error.errorCodes.push('A_LARGER_THAN_B');
    }
    return error;
  },

  // Überprüfe Maximallänge der Lagerseite A
  checkMaxDimensions(a, bearingType) {
    const aCasted = OutputHelper.convertToInteger(a);
    const error = {
      status: false,
      errorCodes: [],
    };
    if (bearingType === 'pyramide' && aCasted > 200) {
      error.status = true;
      error.errorCodes.push('MAX_SIDE_A');
    }
    return error;
  },

  // Überprüfe Mindestgrenzabmessung
  checkMinDimensions(a, b, t, bearingType) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const tCasted = OutputHelper.convertToInteger(t);
    const error = {
      status: false,
      errorCodes: [],
    };

    if (bearingType === 'typ150' || bearingType === 'typ200') {
      if (aCasted < 70) {
        error.status = true;
        error.errorCodes.push('MIN_SIDE_A');
      }
      if (aCasted < (5 * tCasted)) {
        error.status = true;
        error.errorCodes.push('MIN_DIMENSIONS');
      }
    } else if (
      bearingType === 'typ100'
      && (aCasted < 50 || bCasted < 100 || tCasted !== 10)
    ) {
      if (aCasted < (5 * tCasted)) {
        error.status = true;
        error.errorCodes.push('MIN_DIMENSIONS');
      } else if (aCasted > (5 * tCasted) && aCasted < 70) {
        error.status = true;
        error.errorCodes.push('MIN_SIDE_A');
      }
    } else if (bearingType === 'pyramide') {
      if (aCasted < 50) {
        error.status = true;
        error.errorCodes.push('MIN_SIDE_A');
      }
    }

    return error;
  },

  // Überprüfe maximale Lagerdicke
  checkThickness(t, bearingType) {
    const tCasted = OutputHelper.convertToInteger(t);
    const error = {
      status: false,
      errorCodes: [],
    };
    if (bearingType === 'pyramide' && tCasted > 10) {
      error.status = true;
      error.errorCodes.push('MAX_THICKNESS');
    }
    return error;
  },

  // Überprüfe zulässige Verdrehung alpha
  checkTwisting(totalAlpha, permissibleTwisting) {
    const error = {
      status: false,
      errorCodes: [],
    };
    if (totalAlpha > permissibleTwisting) {
      error.status = true;
    }
    return error;
  },

  // Überprüfe resultierende Verdrehung
  checkAlphaResulting(twistingTotal, twistingResulting) {
    const error = {
      status: false,
      errorCodes: [],
    };
    if (
      twistingTotal
      > twistingResulting
    ) {
      error.status = true;
    }
    return error;
  },

  // ### Warnungen
  // Verhältnis Fläche Bohrungen zu Fläche Brutto
  checkAreaHoles(areaHoles, areaBrutto) {
    const areaHolesCasted = OutputHelper.convertToInteger(areaHoles);
    const areaBruttoCasted = OutputHelper.convertToInteger(areaBrutto);
    const error = {
      status: false,
      errorCodes: ['AREA_HOLES_TOO_BIG'],
    };
    if (areaHolesCasted > (0.1 * areaBruttoCasted)) {
      error.status = true;
    }

    return error;
  },

  // Überprüfe maximale Grenzabmessung
  checkMaxDimensionsWarnings(a, t) {
    const aCasted = OutputHelper.convertToInteger(a);
    const tCasted = OutputHelper.convertToInteger(t);
    const error = {
      status: false,
      errorCodes: [],
    };
    if (aCasted > (30 * tCasted)) {
      error.status = true;
      error.errorCodes.push('MAX_DIMENSIONS');
    }
    return error;
  },
};
