export default {
  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  generateUniqueId() { // Public Domain/MIT
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  },
  /**
   * Konvertieren zu Float und entfernten der auffüllenden Nullen mit parseFloat
   *
   * @param value
   * @param decimalPlaces
   * @returns {number}
   */
  convertToFloat(value, decimalPlaces = 2) {
    const valueCasted = this.convertToNumber(value);
    let val = 0;
    if (valueCasted !== 0) {
      val = parseFloat(valueCasted.toFixed(decimalPlaces));
    }
    return val;
  },
  /**
   * Konvertieren von übergebenen Werten zum Typen Number, wenn der Übergabewert Zahlen enthält
   * Beispiel: String '2.45' wird zu Number 2.45 konvertiert
   * Beispiel: Ein String '2.45f' wird zu Number 0 konvertiert
   *
   * @param value
   * @returns {number}
   */
  convertToNumber(value) {
    let number;
    // Die Zahlen von Komma-Schreibweise in Punkt-Schreibweise konvertieren
    const valuePrepared = value.toString().replace(',', '.');
    if (/^-?[\d.]+(?:e-?\d+)?$/.test(valuePrepared)) {
      number = Number(valuePrepared);
    } else {
      number = 0;
    }
    return number;
  },
  /**
   * Konvertieren von übergebenen Werten zu Integer
   * @param value
   * @returns {number}
   */
  convertToInteger(value) {
    const valueCasted = this.convertToNumber(value);
    let val = 0;
    if (valueCasted !== 0) {
      val = parseInt(valueCasted.toFixed(0), 10);
    }
    return val;
  },

  convertToBoolean(value) {
    return Boolean(value);
  },

  /**
   * Download Blob (https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/)
   * @param blob
   * @param filename
   * @returns {HTMLAnchorElement}
   */
  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        // this.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  },
  getBrowserLanguage() {
    return (navigator.language).slice(0, 2) ?? 'en';
  },
};
