<template>
  <div id="app">
    <!-- <AppTopBar /> -->
    <!-- <AppHeader /> -->
    <div class="content-main">
      <router-view />
    </div>
  </div>
</template>

<script>
// import AppTopBar from '@/components/app/AppTopBar.vue';
// import AppHeader from '@/components/app/AppHeader.vue';

import './assets/css/bootstrap.scss';

export default {
  components: {
    // AppTopBar,
    // AppHeader,
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('lang')) {
      this.$i18n.locale = urlParams.get('lang');
    }
  },
};
</script>

<style lang="scss">
  /*html {
    font-size:62.5%;
  }*/

  body {
    background:url('./assets/img/bg_pattern.jpg');
  }

  h3 {
    font-weight:bold;
  }

  img {
    max-width:100%;
  }

  .contentbox {
    background:#fff;
    padding:1.5rem;
  }

  /* Forms */
  .panel {
    margin:0 0 1.5rem;

    &.has-bg {
      background:$gray-200;
      padding:1.5rem;
    }
  }

  .btn-primary {
    color:#fff;
  }

  label,
  h3 {
    .btn{
      color:$primary;
      padding:0 0.5rem;
      line-height: 1;

      &:hover {
        color: darken($primary, 15%);
      }
    }
  }

  th {
    white-space: nowrap;
  }
  td {
    white-space: nowrap;
    .btn-link {
      color:$primary;
      padding:0 0.5rem;
      line-height: 1;
      &:hover {
        color: darken($primary, 15%);
      }
    }
  }
  .alert {
    ul {
      margin:0;
      padding:0 0 0 1rem;
    }
  }
</style>
