import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';

// route level code-splitting
// which is lazy-loaded when the route is visited.
const FixedBearings = () => import(/* webpackChunkName: "FixedBearings" */ '../views/fixedBearings/FixedBearings.vue');
const FixedBearingsDimension = () => import(/* webpackChunkName: "FixedBearingsDimension" */ '../views/fixedBearings/FixedBearingsDimension.vue');
const FixedBearingsEccentricity = () => import(/* webpackChunkName: "FixedBearingsEccentricity" */ '../views/fixedBearings/FixedBearingsEccentricity.vue');
const FixedBearingsEdgeDistanceDIN = () => import(/* webpackChunkName: "FixedBearingsEdgeDistanceDIN" */ '../views/fixedBearings/FixedBearingsEdgeDistanceDIN.vue');
const FixedBearingsEdgeDistanceEC = () => import(/* webpackChunkName: "FixedBearingsEdgeDistanceEC" */ '../views/fixedBearings/FixedBearingsEdgeDistanceEC.vue');
const FixedBearingsEdgeContact = () => import(/* webpackChunkName: "FixedBearingsEdgeContact" */ '../views/fixedBearings/FixedBearingsEdgeContact.vue');
const FixedBearingsTransverseTraction = () => import(/* webpackChunkName: "FixedBearingsTransverseTraction" */ '../views/fixedBearings/FixedBearingsTransverseTraction.vue');
const FixedBearingsDrilling = () => import(/* webpackChunkName: "FixedBearingsDrilling" */ '../views/fixedBearings/FixedBearingsDrilling.vue');
const FixedBearingsTwisting = () => import(/* webpackChunkName: "FixedBearingsTwisting" */ '../views/fixedBearings/FixedBearingsTwisting.vue');

const Tools = () => import(/* webpackChunkName: "Tools" */ '../views/tools/Tools.vue');
const ToolsEccentricity = () => import(/* webpackChunkName: "ToolsEccentricity" */ '../views/tools/ToolsEccentricity.vue');
const ToolsEdgeDistanceDIN = () => import(/* webpackChunkName: "ToolsEdgeDistanceDIN" */ '../views/tools/ToolsEdgeDistanceDIN.vue');
const ToolsEdgeDistanceEC = () => import(/* webpackChunkName: "ToolsEdgeDistanceEC" */ '../views/tools/ToolsEdgeDistanceEC.vue');
const ToolsEdgeContact = () => import(/* webpackChunkName: "ToolsEdgeContact" */ '../views/tools/ToolsEdgeContact.vue');
const ToolsTransverseTraction = () => import(/* webpackChunkName: "ToolsTransverseTraction" */ '../views/tools/ToolsTransverseTraction.vue');
const ToolsDrilling = () => import(/* webpackChunkName: "ToolsDrilling" */ '../views/tools/ToolsDrilling.vue');
const ToolsTwisting = () => import(/* webpackChunkName: "ToolsTwisting" */ '../views/tools/ToolsTwisting.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/verformungslager',
    component: FixedBearings,
    redirect: '/verformungslager/bemessung',
    children: [
      {
        path: 'bemessung',
        component: FixedBearingsDimension,
      },
      {
        path: 'exzentritaet',
        component: FixedBearingsEccentricity,
      },
      {
        path: 'randabstand-din',
        component: FixedBearingsEdgeDistanceDIN,
      },
      {
        path: 'randabstand-ec',
        component: FixedBearingsEdgeDistanceEC,
      },
      {
        path: 'kantenkontakt',
        component: FixedBearingsEdgeContact,
      },
      {
        path: 'querzugkraft',
        component: FixedBearingsTransverseTraction,
      },
      {
        path: 'bohrung',
        component: FixedBearingsDrilling,
      },
      {
        path: 'auflagerverdrehung',
        component: FixedBearingsTwisting,
      },
    ],
  },
  {
    path: '/tools',
    component: Tools,
    redirect: '/tools/exzentritaet',
    children: [
      {
        path: 'exzentritaet',
        component: ToolsEccentricity,
      },
      {
        path: 'randabstand-din',
        component: ToolsEdgeDistanceDIN,
      },
      {
        path: 'randabstand-ec',
        component: ToolsEdgeDistanceEC,
      },
      {
        path: 'kantenkontakt',
        component: ToolsEdgeContact,
      },
      {
        path: 'querzugkraft',
        component: ToolsTransverseTraction,
      },
      {
        path: 'bohrung',
        component: ToolsDrilling,
      },
      {
        path: 'auflagerverdrehung',
        component: ToolsTwisting,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
