import OutputHelper from './OutputHelper';

export default {

  // Auswahl Darstellungstypen
  getSchemeTypeOptions(i18n) {
    return {
      '2d': {
        value: '2d',
        text: i18n.t('bearings.scheme2d'),
      },
      '3d': {
        value: '3d',
        text: i18n.t('bearings.scheme3d'),
      },
    };
  },

  // Lagerdicke
  getBearingThicknessOptions() {
    return {
      10: {
        value: 10,
        text: '10 mm',
      },
      15: {
        value: 15,
        text: '15 mm',
      },
      20: {
        value: 20,
        text: '20 mm',
      },
    };
  },

  // Anzahl Bohrungen
  getBearingHolesOptions() {
    return [0, 1, 2, 3, 4];
  },

  // Lastarten
  getLoadTypeOptions(i18n) {
    return {
      centeredSingleLoad: {
        value: 'centeredSingleLoad',
        text: i18n.t('twisting.loadTypeOptionCenteredSingleLoad'),
      },
      equalDistanceLoad: {
        value: 'equalDistanceLoad',
        text: i18n.t('twisting.loadTypeOptionEqualDistanceLoad'),
      },
      twoSymmetricLoads: {
        value: 'twoSymmetricLoads',
        text: i18n.t('twisting.loadTypeOptionTwoSymmetricLoads'),
      },
      eccentricallySingleLoad: {
        value: 'eccentricallySingleLoad',
        text: i18n.t('twisting.loadTypeOptionEccentricallySingleLoad'),
      },
      deltaLoad: {
        value: 'deltaLoad',
        text: i18n.t('twisting.loadTypeOptionDeltaLoad'),
      },
      torque: {
        value: 'torque',
        text: i18n.t('twisting.loadTypeOptionTorque'),
      },
    };
  },

  // Temperatur
  getTemperatureOptions() {
    return {
      lt0: {
        value: 'lt0',
        text: '<0 °C',
        temperatureFactor: 2,
      },
      gt0: {
        value: 'gt0',
        text: '>0 °C',
        temperatureFactor: 1,
      },
    };
  },

  // Expositionklasse
  getExposureClassOptions() {
    return {
      xc1: {
        value: 'xc1',
        text: 'XC1',
        min: 10,
      },
      xc2: {
        value: 'xc2',
        text: 'XC2',
        min: 20,
      },
      xc3: {
        value: 'xc3',
        text: 'XC3',
        min: 20,
      },
      xc4: {
        value: 'xc4',
        text: 'XC4',
        min: 25,
      },
      xd1: {
        value: 'xd1',
        text: 'XD1',
        min: 40,
      },
      xd2: {
        value: 'xd2',
        text: 'XD2',
        min: 40,
      },
      xd3: {
        value: 'xd3',
        text: 'XD2',
        min: 40,
      },
      xs1: {
        value: 'xs1',
        text: 'XS1',
        min: 40,
      },
      xs2: {
        value: 'xs2',
        text: 'XS2',
        min: 40,
      },
      xs3: {
        value: 'xs3',
        text: 'XS3',
        min: 40,
      },
    };
  },

  // Betongüte
  getConcreteQualityOptions() {
    return {
      'C12/15': {
        value: 'C12/15',
        text: 'C12/15',
        factor: 6.8,
      },
      'C16/20': {
        value: 'C16/20',
        text: 'C16/20',
        factor: 9.1,
      },
      'C20/25': {
        value: 'C20/25',
        text: 'C20/25',
        factor: 11.3,
      },
      'C25/30': {
        value: 'C25/30',
        text: 'C25/30',
        factor: 14.2,
      },
      'C30/37': {
        value: 'C30/37',
        text: 'C30/37',
        factor: 17,
      },
      'C35/45': {
        value: 'C35/45',
        text: 'C35/45',
        factor: 19.8,
      },
      'C40/45': {
        value: 'C40/45',
        text: 'C40/45',
        factor: 22.7,
      },
      'C45/50': {
        value: 'C45/50',
        text: 'C45/50',
        factor: 25.5,
      },
      'C50/60': {
        value: 'C50/60',
        text: 'C50/60',
        factor: 28.3,
      },
    };
  },

  // Auflagerbauweise a1
  getSupportBaseTypeOptions(i18n, bearingSqueeze) {
    const options = {
      blanket: {
        value: 'blanket',
        text: i18n.t('edgeDistanceEC.supportBaseTypeOptionBlanket'),
        distance: 0,
      },
      ribCeiling: {
        value: 'ribCeiling',
        text: i18n.t('edgeDistanceEC.supportBaseTypeOptionRibCeiling'),
        distance: 0,
      },
      bar: {
        value: 'bar',
        text: i18n.t('edgeDistanceEC.supportBaseTypeOptionBar'),
        distance: 0,
      },
    };
    const bearingSqueezeCasted = OutputHelper.convertToFloat(bearingSqueeze);
    if (bearingSqueezeCasted <= 0.15) {
      options.blanket.distance = 25;
      options.ribCeiling.distance = 55;
      options.bar.distance = 90;
    } else if (bearingSqueezeCasted > 0.15 && bearingSqueezeCasted <= 0.4) {
      options.blanket.distance = 30;
      options.ribCeiling.distance = 70;
      options.bar.distance = 110;
    } else if (bearingSqueezeCasted > 0.4) {
      options.blanket.distance = 40;
      options.ribCeiling.distance = 80;
      options.bar.distance = 140;
    }

    return options;
  },

  // Baustoff und Art des Auflagers a2
  getSupportingBearingTypeOptions(i18n, bearingSqueeze) {
    const bearingSqueezeCasted = OutputHelper.convertToFloat(bearingSqueeze);
    const options = {
      line: {
        value: 'line',
        text: i18n.t('edgeDistanceEC.supportingBearingTypeOptionLine'),
      },
      single: {
        value: 'single',
        text: i18n.t('edgeDistanceEC.supportingBearingTypeOptionSingle'),
      },
      concreteLine: {
        value: 'concreteLine',
        text: i18n.t('edgeDistanceEC.supportingBearingTypeOptionConcreteLine'),
      },
      concreteSingle: {
        value: 'concreteSingle',
        text: i18n.t('edgeDistanceEC.supportingBearingTypeOptionConcreteSingle'),
      },
      unreinforcedConcreteLine: {
        value: 'unreinforcedConcreteLine',
        text: i18n.t('edgeDistanceEC.supportingBearingTypeOptionUnreinforcedConcreteLine'),
      },
      unreinforcedConcreteSingle: {
        value: 'unreinforcedConcreteSingle',
        text: i18n.t('edgeDistanceEC.supportingBearingTypeOptionUnreinforcedConcreteSingle'),
      },
      supportingBearingTypeOptionMasonryLine: {
        value: 'supportingBearingTypeOptionMasonryLine',
        text: i18n.t('edgeDistanceEC.supportingBearingTypeOptionMasonryLine'),
      },
      supportingBearingTypeOptionMasonrySingle: {
        value: 'supportingBearingTypeOptionMasonrySingle',
        text: i18n.t('edgeDistanceEC.supportingBearingTypeOptionMasonrySingle'),
      },
    };
    if (bearingSqueezeCasted <= 0.15) {
      options.line.distance = 0;
      options.single.distance = 5;
      options.concreteLine.distance = 5;
      options.concreteSingle.distance = 10;
      options.unreinforcedConcreteLine.distance = 10;
      options.unreinforcedConcreteSingle.distance = 20;
      options.supportingBearingTypeOptionMasonryLine.distance = 10;
      options.supportingBearingTypeOptionMasonrySingle.distance = 20;
    } else if (bearingSqueezeCasted > 0.15 && bearingSqueezeCasted <= 0.4) {
      options.line.distance = 0;
      options.single.distance = 10;
      options.concreteLine.distance = 10;
      options.concreteSingle.distance = 15;
      options.unreinforcedConcreteLine.distance = 15;
      options.unreinforcedConcreteSingle.distance = 25;
      options.supportingBearingTypeOptionMasonryLine.distance = 15;
      options.supportingBearingTypeOptionMasonrySingle.distance = 25;
    } else if (bearingSqueezeCasted > 0.4) {
      options.line.distance = 10;
      options.single.distance = 15;
      options.concreteLine.distance = 15;
      options.concreteSingle.distance = 25;
      options.unreinforcedConcreteLine.distance = 25;
      options.unreinforcedConcreteSingle.distance = 35;
      options.supportingBearingTypeOptionMasonryLine.distance = 0;
      options.supportingBearingTypeOptionMasonrySingle.distance = 0;
    }
    return options;
  },

  // Bauweise delta a2
  getMaxSupportingTypeOptions(i18n) {
    return {
      concreteSteel: {
        value: 'concreteSteel',
        text: i18n.t('edgeDistanceEC.maxSupportingBearingTypeOptionConcreteSteel'),
        distance: 10,
      },
      masonry: {
        value: 'masonry',
        text: i18n.t('edgeDistanceEC.maxSupportingBearingTypeOptionMasonry'),
        distance: 15,
      },
    };
  },

  // Art des Lagers a3
  getSupportedBearingTypeOptions(i18n) {
    return {
      line: {
        value: 'line',
        text: i18n.t('edgeDistanceEC.supportedBearingTypeOptionLine'),
      },
      single: {
        value: 'single',
        text: i18n.t('edgeDistanceEC.supportedBearingTypeOptionSingle'),
      },
    };
  },

  // Bauliche Durchbildung der Bewehrung a3
  getReinforcementOptions(i18n, bearingType) {
    return {
      continuousBars: {
        value: 'continuousBars',
        text: i18n.t('edgeDistanceEC.supportedReinforcementOptionContinuousBars'),
        distance: 0,
      },
      straightBars: {
        value: 'straightBars',
        text: i18n.t('edgeDistanceEC.supportedReinforcementOptionStraightBars'),
        distance: (bearingType === 'line' ? 5 : 15),
      },
      unprotectedBars: {
        value: 'unprotectedBars',
        text: i18n.t('edgeDistanceEC.supportedReinforcementOptionUnprotectedBars'),
        distance: (bearingType === 'line' ? 5 : 15),
      },
      verticalReinforcement: {
        value: 'verticalReinforcement',
        text: i18n.t('edgeDistanceEC.supportedReinforcementOptionVerticalReinforcement'),
        distance: (bearingType === 'line' ? 15 : 0),
      },
    };
  },

  // Art der Kontaktfläche
  getTypeContactSurfaceOptions(i18n) {
    return {
      steel: {
        value: 'steel',
        text: i18n.t('bearings.contactSurfaceSteel'),
      },
      concrete: {
        value: 'concrete',
        text: i18n.t('bearings.contactSurfaceConcrete'),
      },
      wood: {
        value: 'wood',
        text: i18n.t('bearings.contactSurfaceWood'),
      },
    };
  },

  // Pyramidenlager Drehachse rechtwinkling zur Seite
  getPyramideAxisOfRotationOptions() {
    return {
      a: {
        value: 'a',
        text: 'a',
      },
      b: {
        value: 'b',
        text: 'b',
      },
    };
  },
};
