import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import getters from './getters';

// Vuex
Vue.use(Vuex);

const Store = new Vuex.Store({
  state: {
    fixedBearingWidth: 0,
    fixedBearingLength: 0,
    fixedBearingThickness: 10,
    fixedBearingPressureForce: 0,
    fixedBearingHolesDiameter: 0,
    fixedBearingHolesCount: 0,
    fixedBearingHolesPosition: {
      hole1: {
        x: 100,
        y: 65,
      },
      hole2: {
        x: 0,
        y: 0,
      },
      hole3: {
        x: 0,
        y: 0,
      },
      hole4: {
        x: 0,
        y: 0,
      },
    },
    fixedBearingX1: 100,
    fixedBearingY1: 65,
    fixedBearingX2: 0,
    fixedBearingY2: 0,
    fixedBearingX3: 0,
    fixedBearingY3: 0,
    fixedBearingX4: 0,
    fixedBearingY4: 0,
    fixedBearingTwistingA: 0,
    fixedBearingTwistingB: 0,
    fixedBearingTypeContactSurface: 'concrete',
    fixedBearingTwistingWithSurcharge: true,
    fixedBearingPyramideConstantLoads: 1.0,
    fixedBearingPyramideMinVariableLoads: 0.0,
    fixedBearingPyramideMaxVariableLoads: 0.0,
    fixedBearingPyramideTwisting: 0.0,
    fixedBearingPyramideAxisOfRotation: 'a',
    fixedBearingPyramideStorageClass: 1,
    fixedBearingSchemeType: '3d',
    fixedBearingUpdateVisual: 0,
    fixedBearingSelectedBearingType: 'typ100',
  },
  mutations,
  getters,
});

export default Store;
