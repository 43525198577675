<template>
  <div class="dashboard-list">
    <div class="dashboard-list__item">
      <router-link to="/verformungslager">
        <div class="row">
          <div class="col-md-3">
            <img
              src="../../assets/img/festlager_teaser.png"
              alt="Veformungslager"
            />
          </div>
          <div class="col-md-9">
            <h2>{{ $t("dashboard.listFixedBearingsHeadline") }}</h2>
          </div>
        </div>
      </router-link>
    </div>
    <div class="dashboard-list__item">
      <router-link to="/tools">
        <div class="row">
          <div class="col-md-3">
            <img
              src="../../assets/img/festlager_teaser.png"
              alt="Veformungslager"
            />
          </div>
          <div class="col-md-9">
            <h2>{{ $t("dashboard.listToolsHeadline") }}</h2>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardList',
};
</script>

<style scoped lang="scss">
  .dashboard-list__item {
    border-top:3px solid $gray-500;
    background:#fff;
    box-shadow: $box-shadow-sm;
    transition: all 500ms ease;
    margin:0 0 2rem;

    &:hover {
      border-top:3px solid $primary;
      box-shadow: $box-shadow;
    }

    a {
      display: block;
      padding:3rem;
      transition: all 500ms ease-in;

      p {
        color:$text-muted;
      }
    }
  }
</style>
